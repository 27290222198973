import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ImageDropzone from "../../../banners/image-dropzone";
import { toast, ToastContainer } from "react-toastify";
import {
  deleteSspBanner,
  uploadSspImageBanner,
} from "../../../../../../api/api";
import { Formik } from "formik";
import * as Yup from "yup";
import { fetchCreativesByCampaignId } from "../../../../../../api/ssp-api";

import Spinner from "../../../../../../components/spinner";
import { StepsContext } from "../create-campaign";
import { IoIosCloseCircle } from "react-icons/io";

export default function ImageCreatives({
  savedCampaign,
  agencyId,
  token,
  handleComplete,
}) {
  const [type, setType] = useState(1);
  const [visible, setVisible] = useState(true);
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const campaignId = localStorage.getItem("campaignId");
  const { uuid } = useParams();
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  const [selectedImages, setSelectedImages] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const { activeStep, setActiveStep } = useContext(StepsContext);
  const [data, setData] = useState(null);

  const [formData, setFormData] = useState({
    image: null,
    destinationUrl: "",
    campaignId: uuid,
  });

  const handleFileSelect = (file) => {
    setSelectedFiles((prev) => [...prev, file]);
    setIsImageSelected(true);
  };
  useEffect(() => {
    fetchCreativesByCampaignId(uuid, base64Credentials).then((response) => {
      console.log(response.data.filter((item) => item.storageType == "url"));
      setData(response.data.filter((item) => item.storageType == "url"));
    });
  }, [refresh]);

  const uploadData = async (values) => {
    //Files validation
    if (!selectedFiles || selectedFiles.length === 0) {
      setError(true);

      toast.error("At least one banner is required");
      return;
    }

    formData.destinationUrl = values.destinationUrl;

    //creating upload promises for files
    // alert(selectedFiles.length);
    const uploadPromises = selectedFiles.map((selectedFile) => {
      formData.image = selectedFile.file;
      formData.destinationUrl = values.destinationUrl;
      return uploadSspImageBanner(formData, token);
    });
    // alert("Hello");

    try {
      //Uploading files
      const responses = await Promise.all(uploadPromises);
      console.log("All banners uploaded:", responses);
      // Check if all responses are successful, then navigate
      if (responses.every((response) => response.status === 200)) {
        toast.success("All banners uploaded successfully", {
          position: "top-center",
        });
        handleComplete();
      } else {
        console.error("Some uploads failed");
        toast.error("Some uploads failed", {
          position: "top-center",
        });
        setLoading(false);
        setDisabled(false);
      }
    } catch (error) {
      console.error("Error in uploading banners:", error);
      toast.error("Error in uploading banners" + error);
      setLoading(false);
      setDisabled(false);
    }
    setLoading(false);
  };

  if (loading) {
    return <CircularProgress size="10.0rem" />;
  }

  const validationSchema = Yup.object({
    destinationUrl: Yup.string().required("Please enter destination URL"),
  });
  return (
    data && (
      <div className="w-full">
        <div item>
          <ToastContainer />
        </div>
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            destinationUrl: data.destinationUrl,
          }}
          onSubmit={uploadData}
        >
          {({
            handleChange,
            handleSubmit,
            errors,
            values,
            isSubmitting,
            touched,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="w-full space-y-2">
                <div
                  className={`grid  gap-4 ${data.length == 0 ? " grid-cols-1" : " grid-cols-4"}`}
                >
                  {data.map((item) => {
                    return (
                      <div className="border border-border p-4 text-center rounded">
                        {/* <div className="flex justify-end">
                          <IoIosCloseCircle
                            onClick={() => {
                              deleteSspBanner(item.bannerId, token).then(
                                (response) => {
                                  toast.success("Deleted successfully");
                                  setRefresh(refresh + 1);
                                }
                              );
                            }}
                            className=" hover:text-gray-800 transition-all duration-200 size-5 cursor-pointer"
                          />
                        </div> */}
                        <div className="flex justify-center">
                          <img
                            className=" h-48 object-cover py-4"
                            src={item.imageURL}
                          />
                        </div>
                        <p className="text-sm">
                          Image dimensions {item.width}x{item.height}
                        </p>
                      </div>
                    );
                  })}
                  {selectedImages.map((item) => {
                    return (
                      <div className="border hover:text-gray-800 transition-all duration-200 p-4 text-center rounded">
                        <div className="flex justify-end">
                          {/* <IoIosCloseCircle
                            onClick={() => {
                              setSelectedImages(
                                selectedImages.filter((e) => e != item)
                              );
                            }}
                            className="text-textColor size-5 cursor-pointer"
                          /> */}
                        </div>
                        <div className="flex justify-center ">
                          <img
                            className=" h-48 object-cover py-4"
                            src={URL.createObjectURL(item.file)}
                          />
                        </div>
                        <p className="text-sm">
                          Image dimensions {item.dimensions.width}x
                          {item.dimensions.height}
                        </p>
                      </div>
                    );
                  })}

                  <ImageDropzone
                    selectedImages={selectedImages}
                    setSelectedImages={setSelectedImages}
                    onImageUpload={handleFileSelect}
                  />
                </div>

                <div className="space-y-1">
                  <h1 className="text-sm">Destination URL</h1>
                  <input
                    type="text"
                    onChange={handleChange}
                    value={data && data[0].url}
                    name="destinationUrl"
                    className="form-style"
                  />
                  <div>
                    {touched.destinationUrl && errors.destinationUrl && (
                      <div className="text-red-500 text-sm mt-1">
                        {errors.destinationUrl}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex justify-between mt-5">
                <div className="flex space-x-2 mt-6">
                  <button
                    className="py-2  rounded-md  text-textColor  justify-center flex hover:text-primary transition-all duration-200"
                    onClick={() => {
                      setActiveStep(activeStep - 1);
                    }}
                    type="submit"
                  >
                    Back
                  </button>
                  <button
                    className=" bg-primary rounded-md py-2 px-3 text-white flex w-40 justify-center"
                    type="submit"
                  >
                    {isSubmitting ? <Spinner /> : "Save Changes"}
                  </button>
                </div>
                {/* <button
           className="py-2 px-3 rounded-md  text-textColor  w-24 justify-center flex hover:text-primary transition-all duration-200"
            onClick={()=>{
              setActiveStep(activeStep+1)
            }}
            type="submit"
          >
            Skip
          </button> */}
              </div>
            </form>
          )}
        </Formik>
      </div>
    )
  );
}
