import { useNavigate, useParams } from "react-router-dom";

import React, { useContext, useEffect, useState } from "react";
import {
  fetchAdvertiserCampaignStatsApi,
  fetchAdvertiserPublisherStatsApi,
  fetchBannerByCampaignId,
  fetchCampaignBannerStatsApi,
  fetchCampaignDailyStatsApi,
  fetchPublisherBannerStatsApi,
  fetchPublisherWebsites,
  fetchZoneStatsApi,
  getRoles,
  getUserName,
} from "../../../api/ssp-api";
import PublisherStatsTable from "./publisher-stats-table";
import ZoneDataTable from "./zone-stats-table";
import CampaignSummary from "./campaign-summary";
import { useInvoiceData } from "../../../provider/InvoiceContext";
import BannerStatsTable from "./banner-stats-table";
import { addSspInvoice, fetchCampaignStatsApi } from "../../../api/api";
import { BreadcrumbContext } from "../../../layouts/dashboardLayout";
import Loader from "../../../components/loader";
import MixedGraph from "../../overview/mixed-graph";
import PieChart from "../../overview/pie-chart";
import moment from "moment";
import NoData from "../../../components/noData";

export default function SspCampaignData() {
  const { id, startDate, advertiserId } = useParams();
  const [loading, setLoading] = useState(true);
  const [publisherLoading, setPublisherLoading] = useState(true);
  const [zoneLoading, setZoneLoading] = useState(true);
  const [zoneData, setZoneData] = useState([]);
  const [campaignData, setCampaignData] = useState([]);
  const [publisherData, setPublisherData] = useState([]);
  const navigate = useNavigate();
  const [campaignSummary, setCampaignSummary] = useState(null);
  const [summaryLoading, setSummaryLoading] = useState(true);
  const [role, setRole] = useState("");
  const { addData } = useInvoiceData();
  const [disabled, setDisabled] = useState(true);
  const { username, password } = JSON.parse(localStorage.getItem("ssp"));
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  const [bannerLoading, setBannerLoading] = useState(true);
  const [bannerData, setBannerData] = useState([]);
  const { currentPage, setCurrentPage } = useContext(BreadcrumbContext);
  const { setActions } = useContext(BreadcrumbContext);
  useEffect(() => {
    setActions(<div></div>);
    setRole(getRoles());
    setCurrentPage("Campaign Details");
    fetchCampaignDailyStatsApi(id, startDate, base64Credentials)
      .then((response) => {
        // alert('stats')
        const publishers = response.data.publishers.data;
        console.log(publishers);
        const impressions = publishers.reduce(
          (prev, item) => prev + parseFloat(item.impressions),
          0
        );
        const clicks = publishers.reduce(
          (prev, item) => prev + parseFloat(item.clicks),
          0
        );
        const revenue = publishers.reduce(
          (prev, item) => prev + parseFloat(item.revenue),
          0
        );
        const CTR = publishers.reduce(
          (prev, item) =>
            prev + parseFloat(item.clicks) / parseFloat(item.impressions),
          0
        );
        const statsSummary = {
          impressions,
          clicks,
          CTR: Math.ceil(CTR * 100) / 100,
          revenue: Math.ceil(revenue * 100) / 100,
        };
        setCampaignSummary(statsSummary);
        setPublisherData(publishers);
        setCampaignData("Campaigns stats");

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });

    // fetchAdvertiserPublisherStatsApi(advertiserId, startDate, base64Credentials)
    //   .then((response) => {
    //     setPublisherData(response.data.publishers.data);
    //     console.log("Publisher data loaded", response.data.publishers.data);
    //     setPublisherLoading(false);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    fetchZoneStatsApi(id, startDate, base64Credentials)
      .then((response) => {
        setZoneData(response.data.zones.data);
        setZoneLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
    // updateBannerStats(id, base64Credentials);
    fetchCampaignBannerStatsApi(id, startDate, base64Credentials)
      .then((response) => {
        console.log(response.data);
        setBannerData(response.data.banners.data);
        setZoneLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // async function updateBannerStats(id, base64Credentials) {
  //   try {
  //     console.log("Error");
  //     console.log(id);
  //     const response = await fetchPublisherWebsites(base64Credentials, id);
  //     let publishers = response.data;
  //     console.log(response);
  //     for (const publisher of publishers) {
  //       const statsResponse = await fetchPublisherBannerStatsApi(
  //         publisher.publisherId,
  //         startDate,
  //         base64Credentials
  //       );
  //       let bannerStats = statsResponse.data.banners.data.filter(
  //         (banner) => banner.campaignid === id
  //       );
  //       bannerStats = bannerStats.map((bannerStat) => ({
  //         ...bannerStat,
  //         publisherId: publisher.publisherId,
  //         publisherName: publisher.publisherName, // Assuming 'name' is the property for the publisher's name
  //       }));
  //       console.log(bannerStats);
  //       setBannerData((prevData) => [...prevData, ...bannerStats]);
  //       setBannerLoading(false);
  //       // Update your stats table here
  //       // For example: updateStatsTable(banner.bannerid, bannerStats);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching or updating banner stats:", error);
  //   }
  // }

  return campaignSummary == null && publisherData.length < 1 ? (
    //
    <Loader />
  ) : (
    <div header={"Campaign Data"}>
      <div style={{ overflowY: "auto" }}>
        {campaignSummary != null && (
          <div>
            <h4 className>Campaign Summary</h4>
            <CampaignSummary data={campaignSummary} role={role} />
          </div>
        )}
        <div className="mt-6">
          {campaignSummary.impressions + campaignSummary.clicks == 0 ? (
            <NoData />
          ) : (
            <div>
              {publisherData.length < 1 && zoneData.length < 1 && (
                <div className="bg-white border mt-6 border-border rounded p-6 flex flex-col h-60 w-full justify-center items-center">
                  <div className="border-4 border-t-transparent rounded-full h-10 w-10 animate-spin border-primary" />
                  <h1 className="py-2 text-muted">Fetching more data...</h1>
                </div>
              )}
              {publisherData.length > 0 && (
                <div>
                  <div className="grid grid-cols-12 my-6 gap-6">
                    <div className="col-span-8 bg-white border border-border p-6 h-full">
                      <h1 className="font-bold text-lg mb-3">
                        Publishers impressions
                      </h1>
                      <MixedGraph
                        values={publisherData.map((item) =>
                          parseFloat(item.impressions)
                        )}
                        yValuesName={"Impressions"}
                        yValuesName2={"CTR"}
                        xlabels={publisherData.map(
                          (item) => item.publishername
                        )}
                        values2={publisherData.map((item) =>
                          (
                            (parseFloat(item.clicks) * 100) /
                            parseFloat(item.impressions)
                          ).toFixed(2)
                        )}
                      />
                    </div>
                    <div className="col-span-4 h-full bg-white border border-border p-6 ">
                      <h1 className="font-bold text-lg mb-3">
                        Publishers Clicks
                      </h1>
                      <PieChart
                        values={publisherData.map((item) =>
                          parseFloat(item.clicks)
                        )}
                        labels={publisherData.map((item) => item.publishername)}
                      />
                    </div>
                  </div>
                  <h4 className="pt-4 mb-2">Publisher Data</h4>

                  <PublisherStatsTable data={publisherData} role={role} />
                </div>
              )}

              {zoneData.length > 0 && (
                <div>
                  <div className="grid grid-cols-12 my-6 gap-6">
                    <div className="col-span-8 bg-white border border-border p-6 h-full">
                      <h1 className="font-bold text-lg mb-3">
                        Zones impressions
                      </h1>
                      <MixedGraph
                        values={zoneData.map((item) =>
                          parseFloat(item.impressions)
                        )}
                        yValuesName={"Impressions"}
                        yValuesName2={"CTR"}
                        xlabels={zoneData.map((item) => item.zonename)}
                        values2={zoneData.map((item) =>
                          (
                            (parseFloat(item.clicks) /
                              parseFloat(item.impressions)) *
                            100
                          ).toFixed(2)
                        )}
                      />
                    </div>
                    <div className="col-span-4 h-full bg-white border border-border p-6 ">
                      <h1 className="font-bold text-lg mb-3">Zones Clicks</h1>

                      <PieChart
                        values={zoneData.map((item) => parseFloat(item.clicks))}
                        labels={zoneData.map((item) => item.zonename)}
                      />
                    </div>
                  </div>
                  <h4 className="pt-4 mb-2">Zones Data</h4>
                  <ZoneDataTable data={zoneData} role={role} />
                </div>
              )}

              {bannerData.length > 0 && (
                <div>
                  <h4 className="mt-4 mb-2">Banner Data</h4>
                  <BannerStatsTable data={bannerData} role={role} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
