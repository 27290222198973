import { motion } from "framer-motion";
import { figureSeparator } from "../../../util/figureSeparator";

export default function CampaignSummary({ data, role }) {
  const styles = {
    root: {},
    paper: {
      background: "#F7F9FA",
      borderRadius: "16px",
      height: "6rem",
      padding: "4% 8% 0rem 4%",
      textAlign: "left",
      margin: "16px",
    },
    label: {
      color: "#6EBF4A",
      marginBottom: "0.4rem",
      fontSize: "0.9rem",
    },
    figure: {
      color: "#4B4B4B",
      fontWeight: 700,
      fontSize: "1.4rem",
    },
    card: {
      minWidth: 300,
      marginRight: "2rem",
    },
  };
  const { impressions, clicks, CTR, revenue } = data;
  const campaignsSummary = [
    {
      label: "Impressions",
      value: figureSeparator(parseFloat(impressions)),
      icon: "/analytics.svg",
      color: "bg-red-500",
    },
    {
      label: "Clicks",
      value: figureSeparator(parseFloat(clicks)),
      icon: "/cursor.svg",
      color: "bg-blue-500",
    },
    {
      label: "CTR-Clicks",
      value: `${CTR * 100}%`,
      icon: "/ctr.svg",
      color: "bg-orange-500",
    },
    {
      label: "Spent",
      value: `${
        role === "publisher"
          ? "TSh " + figureSeparator(impressions)
          : "$" + figureSeparator(Number(revenue).toFixed(2))
      }`,
      icon: "/bitcoin.svg",
      color: "bg-green-500",
    },
  ];
  const parentVariants = {
    hidden: {
      opacity: 0,
      x: 500,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };
  const childVariants = {
    hidden: {
      opacity: 0,
      y: 40,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };
  return (
    <motion.div
      variants={parentVariants}
      initial="hidden"
      animate="visible"
      className="grid grid-cols-4 gap-4 mt-2"
    >
      {campaignsSummary.map((item, index) => {
        return (
          <motion.div
            variants={childVariants}
            key={index}
            className=" border border-border bg-white group rounded-md py-3 px-4 flex items-center space-x-2 hover:scale-[1.05] cursor-pointer transition-all duration-200"
          >
            <div
              className={`size-14 rounded-full  bg-opacity-10  flex items-center justify-center`}
            >
              <img src={item.icon} />
            </div>
            <div>
              <p className="text-2xl font-bold text-textColor">{item.value}</p>
              <h1 className="text-sm">{item.label}</h1>
            </div>
          </motion.div>
        );
      })}
    </motion.div>
  );
}
