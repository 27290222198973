import React, { useContext, useEffect, useState } from "react";
import { deleteTargetApi, getAudiencesApi } from "../../api/api";
import Loader from "../../components/loader";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../provider/AuthContext";
import { MdOutlineChevronRight, MdOutlineChevronLeft } from "react-icons/md";
import { BreadcrumbContext } from "../../layouts/dashboardLayout";
import DeleteButton from "../../components/deleteButton";
import ModifyButton from "../../components/modify-button";

const MyTable = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [loading, setLoading] = useState(true);
  const [audiences, setAudiences] = useState([]);
  const navigate = useNavigate();
  const { userDetails } = useContext(AuthContext);
  const [refresh, setRefresh] = useState(0);
  const { token, role } = JSON.parse(userDetails);
  const { setActions } = useContext(BreadcrumbContext);

  useEffect(() => {
    setActions(
      <div
        className="py-2 px-3 text-sm bg-primary rounded-md text-white font-semibold cursor-pointer"
        onClick={() => navigate("/audience/create-audience")}
      >
        Add New Audience
      </div>
    );
    getAudiencesApi(token)
      .then((res) => {
        setAudiences(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refresh]);

  const handleEdit = (id) => {
    console.log("campaign-audience id", id);
    navigate(`/audience/edit/${id}`);
  };

  const handleDelete = (id) => {
    // console.log("campaign-audience id", id);
    setLoading(true);
    deleteTargetApi(id)
      .then((res) => {
        // console.log("delete target", res);
        setRefresh(refresh + 1);
        setLoading(false);
        setAudiences(audiences.filter((item) => item.id !== id));
        navigate("/audience");
      })
      .catch((err) => {
        console.log("delete target error", err);
      });
  };
  const [filter, setFilter] = useState("");
  const filteredData = audiences.filter(
    (item) => item.name.toLowerCase().includes(filter.toLowerCase()) // Filter by campaign name
  );
  console.log(filteredData);
  return (
    <div>
      <div className="flex justify-end"></div>
      {loading || audiences === undefined ? (
        <Loader />
      ) : (
        <div className="bg-white px-5 py-5 w-full rounded-md border border-border">
          <div className="flex justify-end space-x-2">
            <input
              label="Filter by name or campaign name"
              placeholder="Search here"
              className="py-2 rounded-md text-sm border-border focus:border-primary focus:ring-primary"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </div>
          <table className="text-sm table w-full">
            <thead className="text-muted">
              <tr className="">
                <th className="py-2 text-sm text-start">Id</th>
                <th className="text-sm text-start py-2">Name</th>
                <th className="text-sm text-start py-2">Start Date</th>
                <th className="text-sm text-start py-2">End Date</th>
                <th className="text-sm text-start py-2"></th>
                <th className="text-sm text-start py-2"></th>
              </tr>
            </thead>
            <tbody className="text-start">
              {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => (
                  <tr className="text-start even:bg-background text-textColor even:border-y-[1px]  hover:text-black w-full  cursor-pointer">
                    <td className="text-start py-3 px-0 ">{item.id}</td>
                    <td className="text-start py-3 px-0 ">{item.name}</td>
                    <td className="text-start py-3 px-0 ">{item.startDate}</td>
                    <td className="text-start py-3 px-0">{item.endDate}</td>

                    <td>
                      <ModifyButton
                        id={item.id}
                        onDelete={() => {
                          handleDelete(item.id);
                        }}
                        onEdit={() => {
                          handleEdit(item.id);
                        }}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="flex space-x-1 items-center text-sm mt-4 justify-end text-gray-500 me-3">
            <h1 className=" translate-x-5">Rows per page</h1>
            <select
              value={rowsPerPage}
              onChange={(e) => {
                setRowsPerPage(e.target.value);
              }}
              className="px-6 py-2 h-12 border-0 focus:border-none focus:ring-0 text-sm"
            >
              <option className="" value={5}>
                5
              </option>
              <option className="" value={8}>
                8
              </option>
            </select>
            <div
              onClick={() => {
                if (page > 0) {
                  setPage(page - 1);
                }
              }}
              className="size-5 bg-primary rounded-md flex items-center text-white justify-center cursor-pointer"
            >
              <MdOutlineChevronLeft />
            </div>
            <h1>
              {page + 1} out of {Math.ceil(audiences.length / rowsPerPage)}
            </h1>
            <div
              onClick={() => {
                if (page < Math.round(audiences.length / rowsPerPage)) {
                  setPage(page + 1);
                }
              }}
              className="size-5 bg-primary rounded-md flex items-center cursor-pointer text-white justify-center"
            >
              <MdOutlineChevronRight />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyTable;
