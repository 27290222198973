import { useEffect, useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const WebsiteNavbar = () => {
  const [isScrolling, setIsScrolling] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      if (window.scrollY > 100) {
        setIsScrolling(true);
      } else {
        setIsScrolling(false);
      }
    });
  }, []);
  const navigate = useNavigate();
  return (
    <div className={`fixed w-full z-50`}>
      {showMenu && (
        <div className="fixed inset-0 bg-dark">
          <div className="flex flex-col items-center p-12 justify-start h-screen text-lg space-y-6">
            <div className="flex justify-end w-full mb-24">
              <AiOutlineClose
                onClick={() => {
                  setShowMenu(false);
                }}
                className=" text-muted"
              />
            </div>

            <button
              onClick={() => {
                setShowMenu(false);
                navigate("/register/?role=publisher");
              }}
              className="py-4 border border-primary text-primary hover:bg-primary transition-all duration-200 hover:text-white rounded-full px-6"
            >
              Book a Demo
            </button>
          </div>
        </div>
      )}
      <div className="bg-[#575757] w-11/12 md:w-10/12 2xl:w-8/12 mx-auto rounded-full mt-3">
        <div className="flex justify-between items-center w-10/12 md:w-11/12 2xl:w-11/12 mx-auto py-3">
          <img
            onClick={() => {
              navigate("/");
            }}
            className=" h-8 md:h-10 cursor-pointer"
            src="/Kwanza 2.svg"
          />
          <div className="block md:hidden">
            <AiOutlineMenu
              onClick={() => {
                setShowMenu(true);
              }}
              className="text-xl"
            />
          </div>
          <div className="hidden md:block"></div>
          <div className="hidden md:block ">
            <div className="flex space-x-4">
              <div className="flex space-x-4 items-center ">
                <div
                  onClick={() => {
                    navigate("/login");
                  }}
                  className=" cursor-pointer hover:text-primary transition-all duration-200"
                >
                  Login
                </div>
                <button
                  onClick={() => {
                    navigate("/register/?role=advertiser");
                  }}
                  className="py-3 border border-primary text-primary hover:bg-primary transition-all duration-200 hover:text-white rounded-full px-6"
                >
                  Book a Demo
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebsiteNavbar;
