import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CampaignTable from "./campaigns-ssp-table";
import { MdOutlineChevronRight, MdOutlineChevronLeft } from "react-icons/md";
import moment from "moment/moment";
import { deleteSspCampaignApi } from "../../../api/ssp-api";
import Spinner from "../../../components/spinner";
import { toast } from "react-toastify";
import DeleteButton from "../../../components/deleteButton";
import ModifyButton from "../../../components/modify-button";

const SspTable = ({
  data,
  handleAdminView,
  handlePublisherView,
  role,
  onRefresh,
}) => {
  const [deleting, setDeleting] = useState(false);
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();

  if (role === "publisher") {
    return (
      <CampaignTable data={data} handlePublisherView={handlePublisherView} />
    );
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  const [filter, setFilter] = useState("");
  const filteredRows = data.filter((item) =>
    item.campaignName.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div>
      <div className="bg-white px-5 py-5 w-full rounded-md  border border-border ">
        <div className="flex justify-end">
          <input
            className="py-2 rounded-md border-border text-sm  focus:border-primary focus:ring-primary"
            placeholder="Search here"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
        <table className="text-sm table w-full mt-4">
          <thead className="text-muted">
            <tr className="">
              <th className="py-3 text-sm text-start">Id</th>
              <th className="text-sm text-start py-3">Campaign Name</th>
              <th className="text-sm text-start py-3">Start Date</th>
              {/* <th className="text-sm text-start py-3">End Date</th> */}
              <th className="text-sm text-start py-3">Impressions</th>
              <th className="text-sm text-start py-3">Clicks</th>
              <th className="text-sm text-start py-3">Status</th>
              <th className="text-sm text-start py-3"></th>
              <th className="text-sm text-start py-3"></th>
              <th className="text-sm text-start py-3"></th>
            </tr>
          </thead>
          <tbody className="text-start">
            {filteredRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item) => (
                <tr
                  key={item.id}
                  className="text-start even:bg-background text-textColor even:border-y-[1px]  hover:text-textColor w-full  cursor-pointer"
                >
                  <td className="text-start py-3 px-0 ">{item.campaignId}</td>
                  <td className="text-start py-3 px-0 ">{item.campaignName}</td>
                  <td className="text-start py-3 px-0 ">
                    {moment(
                      `${item.startDate.year}-${item.startDate.month}-${item.startDate.day}`
                    ).format("yyy-MM-DD")}
                  </td>
                  {/* <td className="text-start py-3 px-0 ">
                    {moment(item.endDate).format("MMM DD, YYYY")}
                  </td> */}
                  <td className="text-start py-3 px-0 ">
                    {" "}
                    {item.weight === "0" ? (
                      <div className="text-secondary">Paused</div>
                    ) : (
                      <div className="text-green-500">Active</div>
                    )}{" "}
                  </td>

                  <td className="text-start px-0 py-3">
                    <button
                      className="font-bold py-1 px-2 bg-primary text-white rounded"
                      onClick={() =>
                        handleAdminView(item.campaignId, item.advertiserId)
                      }
                    >
                      View Stats
                    </button>
                  </td>
                  <td>
                    <ModifyButton
                      id={item.campaignId}
                      onEdit={() => {
                        navigate(`/agency/campaign/edit/${item.campaignId}`);
                      }}
                      onDelete={() => {
                        deleteSspCampaignApi(item.campaignId).then((res) => {
                          toast.success("Deleted successfully");
                          onRefresh();
                        });
                      }}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="flex space-x-1 items-center text-sm mt-4 justify-end text-gray-500 me-3">
          <h1 className=" translate-x-5">Rows per page</h1>
          <select
            value={rowsPerPage}
            onChange={(e) => {
              setRowsPerPage(e.target.value);
            }}
            className="px-6 py-2 h-12 border-0 focus:border-none focus:ring-0 text-sm"
          >
            <option className="" value={5}>
              5
            </option>
            <option className="" value={8}>
              8
            </option>
          </select>
          <div
            onClick={() => {
              if (page > 0) {
                setPage(page - 1);
              }
            }}
            className="size-5 bg-primary rounded-md flex items-center text-white justify-center cursor-pointer"
          >
            <MdOutlineChevronLeft />
          </div>
          <h1>
            {page + 1} out of {Math.ceil(data.length / rowsPerPage)}
          </h1>
          <div
            onClick={() => {
              if (page < Math.round(data.length / rowsPerPage)) {
                setPage(page + 1);
              }
            }}
            className="size-5 bg-primary rounded-md flex items-center cursor-pointer text-white justify-center"
          >
            <MdOutlineChevronRight />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SspTable;
