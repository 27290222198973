import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { navigation } from "../components/wrapper/constants";
import { logoutAPI } from "../api/api";
import {
  act,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { IoArrowBack, IoLogOut } from "react-icons/io5";
import moment, { duration } from "moment";
import { AnimatePresence, motion } from "framer-motion";
import { CiSettings } from "react-icons/ci";
import {
  IoIosArrowDown,
  IoIosArrowForward,
  IoIosArrowUp,
} from "react-icons/io";
import Navbar from "./navbar";
import { set } from "lodash";
import DeleteModal from "../components/deleteModal";

export const BreadcrumbContext = createContext();
export const DeletionContext = createContext();

const DashboardLayout = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const user = localStorage.getItem("kwanza");
  const [currentPage, setCurrentPage] = useState(null);
  const [overrideHeading, setOverrideHeading] = useState(null);
  const userObj = user && JSON.parse(user);
  const [showLogout, setShowLogout] = useState(false);
  const logoutRef = useRef();
  const [actions, setActions] = useState(<div></div>);
  const [expandSetting, setExpandSetting] = useState(false);
  const [role, setRole] = useState(null);
  const [showArrow, setShowArrow] = useState(true);
  const [selectedItem, setSelectedItem] = useState(100);
  const [showConfirmDeletion, setShowConfirmDeletion] = useState();
  const [onDelete, setOnDelete] = useState();

  useEffect(() => {
    console.log("Role ", userObj);
    if (userObj) {
      setRole(userObj.role);
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    window.onclick = (e) => {
      if (showLogout == true) {
        setShowLogout(false);
      }
    };
  }, [showLogout]);

  let tab =
    navigation.filter((item) =>
      item.path.includes(pathname.split("/")[pathname.split("/").length - 1])
    ).length > 0 &&
    navigation.filter((item) =>
      item.path.includes(pathname.split("/")[pathname.split("/").length - 1])
    )[0];
  const [expanded, setExpended] = useState(false);
  const [isDark, setIsDark] = useState(false);
  return (
    userObj && (
      <div
        className={`${isDark ? "dark" : "light"} font-medium transition-all duration-200  text-sm text-textColor`}
      >
        <motion.div className="flex  ">
          <motion.div
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            initial={{ opacity: 0, x: -300 }}
            className="  w-[18%] 2xl:w-[13%] min-h-screen border-r-2 flex flex-col  bg-background  dark:bg-dark border-r-border fixed  "
          >
            <div
              onClick={() => {
                navigate("/");
              }}
              className="object-cover bg-white dark:bg-transparent h-[82px] border-b-2 border-border dark:border-transparent"
            >
              <img
                className="py-4 px-6 h-[75px]"
                src={isDark ? "/Kwanza 2.svg" : "/Kwanza 1.svg"}
              />
            </div>
            <div className="px-6 ">
              <div className="space-y-1 pt-3 text-muted dark:text-white ">
                {/* {pathname.split("/")[1]} */}
                {navigation.map((item, index) => {
                  return (
                    item.roles.includes(role) && (
                      <div>
                        <div
                          onClick={() => {
                            if (item.children.length > 0) {
                              setSelectedItem(index);
                              setExpended(!expanded);
                            } else {
                              navigate(
                                `${role != "admin" ? (item.path == "/overview" ? "" : role) : ""}${item.path}`
                              );
                            }
                          }}
                          className={`flex items-center py-2 px-3 cursor-pointer justify-between 
                        ${item.routes.reduce((acc, currentItem) => pathname.split("/").includes(currentItem) || acc, false) && "hover:bg-background"}  transition-all text-base duration-200 rounded-md  space-x-3 
                        ${item.routes.reduce((acc, currentItem) => pathname.split("/").includes(currentItem) || acc, false) ? "bg-lightPrimary dark:bg-primary text-primary  dark:text-white hover:bg-lightPrimary dark:hover:bg-primary" : " text-dark dark:text-white hover:bg-black hover:bg-opacity-5 dark:hover:bg-black dark:hover:bg-opacity-20 dark:text-opacity-90"} `}
                        >
                          <div className="flex justify-between w-full items-center">
                            <div className="flex space-x-3 items-center">
                              <div>{item.icon}</div>
                              <h1>{item.name}</h1>
                            </div>
                            {item.children.length > 0 ? (
                              <div className="">
                                <IoIosArrowDown className="" />
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </div>
                        {/* {pathname} */}
                        {expanded && index == selectedItem && (
                          <div className="ml-11 space-y-2 mt-2">
                            {item.children.map((e) => {
                              return (
                                <div
                                  onClick={() => {
                                    setExpended(true);
                                    navigate(e.path);
                                  }}
                                  className="cursor-pointer font-medium hover:text-primary"
                                >
                                  {e.name}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    )
                  );
                })}
              </div>
            </div>
            <div className="text-textColor mt-auto mb-12 ">
              {
                <div className="space-y-1 mt-2">
                  {[
                    {
                      title: "Terms & Conditions",
                      path: "/terms-of-use",
                    },
                    { title: "Privacy Policy", path: "/privacy-policy" },
                  ].map((item) => {
                    return (
                      <div
                        onClick={() => {
                          navigate(item.path);
                        }}
                        className={`flex  hover:text-primary cursor-pointer  transition-all text-sm duration-100 rounded-md  ms-8 text-muted  `}
                      >
                        <h1 className={``}>{item.title}</h1>
                      </div>
                    );
                  })}
                </div>
              }
            </div>
          </motion.div>
          <div className="w-[82%] 2xl:w-[87%] bg-background  min-h-screen overflow-y-scroll ms-auto">
            <Navbar isDark={isDark} setIsDark={setIsDark} />

            <div className=" mx-auto">
              <div className=" pt-0">
                {" "}
                <div
                  ref={logoutRef}
                  className="  z-20  mt-20  w-full    me-auto flex justify-between py-4 px-8  items-center"
                >
                  {tab == "" ? (
                    <div className="flex items-center space-x-2 ">
                      {showArrow && (
                        <IoArrowBack
                          onClick={() => {
                            navigate(-1);
                          }}
                          className=" text-slate-500 size-9 border-gray-100 border rounded-full hover:bg-primary hover:bg-opacity-10 cursor-pointer p-2 "
                        />
                      )}{" "}
                      <h1 className="font-bold text-lg">{currentPage}</h1>
                    </div>
                  ) : (
                    <div className="flex space-x-2 items-center">
                      {/* <div className=" text-xl font-bold">{tab.icon}</div> */}
                      <h1 className="font-bold text-xl">
                        {tab.name ?? currentPage}{" "}
                      </h1>
                    </div>
                  )}
                  <div>{actions}</div>
                </div>
              </div>
              <div className="px-8    mb-12 ">
                <BreadcrumbContext.Provider
                  value={{
                    currentPage,
                    setCurrentPage,
                    setShowArrow,
                    setActions,
                    setOverrideHeading,
                  }}
                >
                  <DeletionContext.Provider
                    value={{
                      onDelete,
                      showConfirmDeletion,
                      setShowConfirmDeletion,
                      setOnDelete,
                    }}
                  >
                    <DeleteModal />
                    <AnimatePresence mode="wait">
                      <motion.div
                        key={pathname}
                        animate={{
                          opacity: 1,
                          transition: { duration: 0.5, ease: "easeInOut" },
                        }}
                        initial={{ opacity: 0 }}
                      >
                        <Outlet />
                      </motion.div>
                      f
                    </AnimatePresence>
                  </DeletionContext.Provider>
                </BreadcrumbContext.Provider>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    )
  );
};

export default DashboardLayout;
