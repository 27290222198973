import React, { useContext, useEffect, useState } from "react";

import {
  useLocation,
  useNavigate,
  useParams,
  withRouter,
} from "react-router-dom";
import { styles } from "./date-picker-style";
import { useCampaignData } from "../../../provider/DataContext";
import { toast, ToastContainer } from "react-toastify";
import { BreadcrumbContext } from "../../../layouts/dashboardLayout";
import Spinner from "../../../components/spinner";
import {
  editCampaigns,
  getCampaignById,
  submitBudgetApi,
} from "../../../api/api";
import { AuthContext } from "../../../provider/AuthContext";
import moment from "moment";
import { CampaignContext } from "./create-campaign-tabs";
import { toDays } from "../../../util";

const CampaignInfo = ({
  classes,
  advertiserData,
  handleSubmitCampaignInfo,
}) => {
  const navigate = useNavigate();
  const [advertiserId, setAdvertiserId] = useState(0);
  const [campaignName, setCampaignName] = useState("");
  const [campaignDestination, setCampaignDestination] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { uuid } = useParams();
  const { userDetails } = useContext(AuthContext);
  const { token, role, agencyId } = JSON.parse(userDetails);
  const { addData } = useCampaignData();
  const { data } = useCampaignData();
  const [newData, setNewData] = useState("");
  const { setCurrentPage, setActions } = useContext(BreadcrumbContext);
  const [campaign, setCampaign] = useState(null);
  const { activeStep, setActiveStep } = useContext(CampaignContext);

  useEffect(() => {
    if (advertiserData.length > 0) {
      getCampaignById(token, uuid).then((response) => {
        console.log(response.data);
        setCampaign(response.data);
      });
    }
  }, [advertiserData]);

  useEffect(() => {
    setActions(<div></div>);
    setCurrentPage("Create DSP Campaign");
    if (data.length > 0) {
      setCampaignName(
        data[0].campaignName == "Campaign 1" ? null : data[0].campaignName
      );
      setCampaignDestination(data[0].campaignDestination);
      setStartDate(data[0].startDate);
      setEndDate(data[0].endDate);
    }
  }, []);
  const submitCampaignBudget = (campaign, budget) => {
    try {
      //calculate days from campaign startDate and endDate without using toDays function
      let startDate = new Date(campaign.startDate);
      let endDate = new Date(campaign.endDate);
      let days = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;
      console.log("days", days);
      let daily = budget / days;
      const budgetData = {
        totalBudget: budget,
        dailyBudget: parseFloat(daily),
      };
      console.log("budgetData", budgetData);
      submitBudgetApi(budgetData, uuid)
        .then((res) => {
          console.log("res", res);
        })
        .catch((err) => {
          console.log("error", err);
        });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (campaignName !== "" && !advertiserId && campaignDestination !== "") {
      toast.error("Please fill all the fields");
      return;
    }
    setIsLoading(true);
    const data = {
      advertiserId: parseInt(e.target.advertiser.value),
      campaignDestination: e.target.campaignDestination.value,
      campaignName: e.target.campaignName.value,
      startDate: new Date(e.target.startDate.value),
      endDate: new Date(e.target.endDate.value),
      totalBudget: parseFloat(e.target.budget.value),
      dailyBudget: 10,
      audienceId: 1,
      status: "draft",
      id: parseInt(uuid),
    };
    console.log(data);
    try {
      console.log("Uploading now");
      submitCampaignBudget(data, parseFloat(e.target.budget.value));
      editCampaigns(token, data, uuid).then((response) => {
        console.log(response);
        setActiveStep(activeStep + 1);
        toast.success("Changes Saved");
        setIsLoading(false);
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return (
    campaign && (
      <div className="border border-border rounded-md p-10 bg-white">
        <ToastContainer />
        <form onSubmit={onSubmit}>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="text-sm">Campaign Name*</label>
              <input
                className="form-style"
                required
                defaultValue={
                  campaign.name == "Campaign 1" ? "" : campaign.name
                }
                name="campaignName"
                placeholder="Enter campaign name"
                error={!campaignName}
              />
            </div>
            <div>
              <label className="text-sm">Advertiser*</label>
              <select
                name="advertiser"
                required
                defaultValue={campaign.advertiser_id}
                className="form-style"
              >
                <option value="">Select advertiser</option>
                {advertiserData.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.company}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="text-sm">Start date*</label>
              <input
                name="startDate"
                required
                defaultValue={moment(campaign.activate_time).format(
                  "yyy-MM-DD"
                )}
                variant="outlined"
                type="date"
                className="form-style"
              />
            </div>
            <div>
              <label className="text-sm">End date*</label>
              <input
                name="endDate"
                required
                defaultValue={moment(campaign.expire_time).format("yyy-MM-DD")}
                type="date"
                className="form-style"
              />
            </div>
            <div>
              <label className="text-sm">Campaign Destination*</label>
              <input
                required
                defaultValue={
                  campaign.campaignDestination == "kwanza.co.tz"
                    ? null
                    : campaign.campaignDestination
                }
                className="form-style"
                placeholder="Enter campaign destination"
                name="campaignDestination"
                error={!campaignDestination}
              />
            </div>
            <div>
              <label className="text-sm">Campaign budget*</label>
              <input
                className="form-style"
                required
                type="number"
                placeholder="Enter budget"
                defaultValue={parseFloat(campaign.total_budget)}
                name="budget"
              />
            </div>
          </div>

          <div></div>
          <div>
            <div className="w-full flex justify-start space-x-3">
              <button
                className=" bg-primary text-white rounded-md py-2 px-4 w-40 flex justify-center font-medium"
                type="submit"
                style={{ marginTop: "20px" }}
              >
                {isLoading ? <Spinner /> : "Save & Continue"}
              </button>
            </div>
          </div>
        </form>
      </div>
    )
  );
};

export default CampaignInfo;
