import React, { useContext, useEffect, useState } from "react";

import { countries } from "./country";
import { interests as goals, iabCategories } from "./constant";
import {
  fetchCountryForecast,
  getIabApi,
  listPretargetsApi,
  newAudienceApi,
} from "../../api/api";

import { BreadcrumbContext } from "../../layouts/dashboardLayout";
import { IoCloseSharp } from "react-icons/io5";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../provider/AuthContext";
import PieChart from "../overview/pie-chart";
import { FaChartPie } from "react-icons/fa";
import BarGraph from "../overview/bar-graph";
import Spinner from "../../components/spinner";

function NewAudience({ fromCampaign = false, setShow }) {
  const [country, setCountry] = useState([]);
  const [interest, setInterest] = useState([]);
  const [interests, setInterests] = useState([]);
  const [goal, setGoal] = useState([]);
  const [audienceName, setAudienceName] = useState("");
  const [activateTime, setActivateTime] = useState(new Date());
  const [expireTime, setExpireTime] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [pretargets, setPretargets] = useState([]);
  const [billingId, setBillingId] = useState("");
  const [carrier, setCarrier] = useState([]);
  const [os, setOs] = useState([]);
  const { setCurrentPage } = useContext(BreadcrumbContext);
  const { userDetails } = useContext(AuthContext);
  const [refresh, setRefresh] = useState(0);
  const { token, role } = JSON.parse(userDetails);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [forecastData, setForecastData] = useState(null);
  const [showForecastData, setShowForecastData] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  useEffect(() => {
    try {
      fetchCountryForecast(selectedCountry, token)
        .then((data) => {
          console.log(data);
          const receivedData = data.data;
          const convertAge = (ageObject) => new Map(Object.entries(ageObject));
          const convertDevices = (deviceObject) =>
            new Map(Object.entries(deviceObject));
          const convertGender = (genderObject) =>
            new Map(Object.entries(genderObject));
          const ageResult = convertAge(receivedData.age);
          const deviceResult = convertDevices(receivedData.device);
          const genderResult = convertGender(receivedData.gender);
          const convertSite = (siteObject) =>
            new Map(Object.entries(siteObject));
          const sitesResult = convertSite(receivedData.sites);
          const keys = Array.from(sitesResult.values());
          setForecastData({
            ...receivedData,
            age: ageResult,
            device: deviceResult,
            gender: genderResult,
            sites: sitesResult,
          });
          console.log(forecastData);
        })
        .catch((error) => {
          setForecastData(null);
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, [selectedCountry]);
  const carriers = [
    {
      carrierId: 71563,
      carrierName: "Tigo",
    },
    {
      carrierId: 71560,
      carrierName: "Zantel",
    },
    {
      carrierId: 71532,
      carrierName: "Airtel",
    },
    {
      carrierId: 71561,
      carrierName: "VodaCom",
    },
  ];

  const operatingSystem = [
    {
      osId: 1,
      osName: "Android",
    },
    {
      osId: 2,
      osName: "iOS",
    },
    {
      osId: 3,
      osName: "Windows",
    },
    {
      osId: 4,
      osName: "Macintosh",
    },
  ];

  const navigate = useNavigate();

  const { setActions } = useContext(BreadcrumbContext);
  useEffect(() => {
    setActions(<div></div>);
    setCurrentPage("Add new audiance");
    getIabApi()
      .then((response) => {
        setInterests(response.data ? response.data : []);
      })
      .catch((error) => {
        console.log(error);
      });
    listPretargetsApi()
      .then((response) => {
        setPretargets(response.data ? response.data : []);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onHandleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "audienceName":
        setAudienceName(value);
        break;
      case "goal":
        setGoal(value);
        break;
      case "billingId":
        setBillingId(value);
        break;
      case "interest":
        setInterest(value);
        break;
      case "country":
        setCountry(value);
        break;
      case "carrier":
        setCarrier(value);
        break;
      case "os":
        setOs(value);
        break;
      default:
        break;
    }
  };

  const onStartDateChange = (activateTime) => {
    setActivateTime(activateTime._d);
  };

  const onEndDateChange = (expireTime) => {
    setExpireTime(expireTime._d);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (interest.length == 0) {
      console.log("empty field");
      toast.error("Please select interest");
      // Handle error state
    } else {
      setIsLoading(true);
      console.log(e.target.activateTime.value);
      console.log(e.target.expireTime.value);
      let data = {
        audienceName: audienceName,
        activateTime: new Date(e.target.activateTime.value),
        expireTime: new Date(e.target.expireTime.value),
        // audienceGoals: goal.join(","),
        country: e.target.country.value,
        interest: interest.map((item) => item.value).join(","),
        billingId,
        // carrier: selectedCarriers.join(","),
        // os: selectedOs.join(","),
      };
      console.log(data);
      newAudienceApi(data)
        .then((response) => {
          console.log(response);
          if (!fromCampaign) {
            navigate("/audience");
          } else {
            setShow(false);
          }
          toast.success("Added successfully");
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("error", error.response.data.message);
          toast.error(error.response.data.message);
          setIsLoading(false);
        });
    }
  };

  const [selectedForecastTab, setSelectedForecastTab] = useState(0);
  const renderValue = (selected) => (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
      {selected.map((value) => {
        const selectedInterest = interests.find(
          (item) => item.iab_id === value
        );
        return (
          <Chip
            style={{ backgroundColor: "#6EBF4A", color: "#ffffff" }}
            key={value}
            label={`${selectedInterest?.name} (${value})`} // Display both name and value
          />
        );
      })}
    </Box>
  );

  return (
    // <LocalizationProvider dateAdapter={AdapterDateFns}>

    <form
      onSubmit={onSubmit}
      className={
        fromCampaign == false && `bg-white p-10 border border-border rounded-md`
      }
    >
      {forecastData && showForecastData == true && (
        <div className=" bg-black fixed h-screen w-screen inset-0 z-50 bg-opacity-20 flex justify-center items-center">
          <div className="bg-white w-6/12 2xl:w-4/12 border border-border rounded-lg p-6">
            <div className="flex justify-between items-center">
              <h1 className="text-xl font-semibold">
                {forecastData.countryName} Forecast
              </h1>
              <IoCloseSharp
                size={20}
                className="text-muted cursor-pointer"
                onClick={() => {
                  setShowForecastData(false);
                }}
              />
            </div>
            <div className="mt-4 space-y-2">
              {[
                { title: "Country Name", value: forecastData.countryName },
                { title: "Country Code", value: forecastData.currencyCode },
                { title: "Impressions", value: forecastData.impressions },
                { title: "Uniques", value: forecastData.uniques },
              ].map((item) => {
                return (
                  <div className="flex justify-between">
                    <p className="text-muted">{item.title}</p>
                    <h1 className="">{item.value}</h1>
                  </div>
                );
              })}
            </div>
            <div className="  mt-4 border-t border-border py-2">
              <div>
                <div className="flex flex-wrap space-x-2">
                  {["Devices", "Age", "Gender", "Sites"].map((item, index) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedForecastTab(index);
                        }}
                        className={`${selectedForecastTab == index ? "bg-primary bg-opacity-30" : "text-muted hover:scale-105 transition-all duration-200"} py-1 px-3 rounded-full cursor-pointer`}
                      >
                        {item}
                      </div>
                    );
                  })}
                </div>
                {selectedForecastTab == 0 && (
                  <PieChart
                    labels={["Mobile", "Tablet", "Desktop", "Connected TV"]}
                    colors={["#6DBF48", "#FBC23D", "#082A7B", "#EB345F"]}
                    values={[
                      forecastData.device.get("Mobile").replace("%", ""),
                      forecastData.device.get("Tablet").replace("%", ""),
                      forecastData.device.get("Desktop").replace("%", ""),
                      forecastData.device
                        .get("Connected TV")
                        .replace("%", "")
                        .replace("<", ""),
                    ]}
                  />
                )}
                {selectedForecastTab == 1 && (
                  <PieChart
                    labels={[
                      "18-24",
                      "25-34",
                      "35-44",
                      "45-54",
                      "55-64",
                      "65+",
                      "Unknown",
                    ]}
                    colors={[
                      "#6DBF48",
                      "#FBC23D",
                      "#082A7B",
                      "#EB345F",
                      "#15E6FF",
                      "#8A15FF",
                      "#1556FF",
                      "#FF8515",
                    ]}
                    values={[
                      forecastData.age.get("18-24").replace("%", ""),
                      forecastData.age.get("25-34").replace("%", ""),
                      forecastData.age.get("35-44").replace("%", ""),
                      forecastData.age.get("45-54").replace("%", ""),
                      forecastData.age.get("55-64").replace("%", ""),
                      forecastData.age.get("65+").replace("%", ""),
                      forecastData.age.get("Unknown").replace("%", ""),
                    ]}
                  />
                )}
                {selectedForecastTab == 2 && (
                  <PieChart
                    labels={["Male", "Female", "Unknown"]}
                    colors={["#6DBF48", "#FBC23D", "#082A7B"]}
                    values={[
                      forecastData.gender.get("Male").replace("%", ""),
                      forecastData.gender.get("Female").replace("%", ""),
                      forecastData.gender.get("Unknown").replace("%", ""),
                    ]}
                  />
                )}
                {selectedForecastTab == 3 && (
                  <BarGraph
                    values={Array.from(forecastData.sites.values()).map(
                      (item) => item.replace("M", "")
                    )}
                    yValuesName="Visits (M)"
                    ylabels={Array.from(forecastData.sites.values())}
                    xlabels={Array.from(forecastData.sites.keys())}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="text-sm">Audience name*</label>
          <input
            className="form-style"
            required
            name="audienceName"
            value={audienceName}
            onChange={onHandleChange}
          />
        </div>

        <div className=" ">
          <div>
            <label className="text-sm">Country*</label>
            <select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              className="form-style"
              required
              onChange={(e) => {
                setSelectedCountry(e.target.value);
                setShowForecastData(true);
              }}
              name="country"
            >
              <option>Select Country</option>
              {countries.map(({ iso3, label }, index) => (
                <option value={iso3} key={index}>
                  {label}
                </option>
              ))}
            </select>
            {selectedCountry && forecastData && (
              <div className="flex space-x-1 items-center transition-all duration-200 hover:scale-105 ">
                <FaChartPie className="text-primary" />
                <h1
                  onClick={() => {
                    setShowForecastData(true);
                  }}
                  className="text-primary font-bold px-1 py-2 cursor-pointer"
                >
                  View Forcast
                </h1>
              </div>
            )}
          </div>
        </div>
        <div>
          <div>
            <label className="text-sm">Start Date*</label>
            <input
              type="date"
              name="activateTime"
              required
              value={activateTime}
              onChange={onStartDateChange}
              className="form-style"
            />
          </div>
        </div>
        <div>
          <div>
            <label className="text-sm">End Date*</label>
            <input
              type="date"
              name="expireTime"
              required
              value={expireTime}
              onChange={onEndDateChange}
              className="form-style"
            />
          </div>
        </div>
        <div className="">
          <div className="">
            <div className="flex space-x-2">
              {/* <h4 className="font-bold">Audience</h4> */}
              <p className="text-sm">Who should see your ad?</p>
            </div>

            <select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              className="form-style"
              value={billingId}
              required
              name="billingId"
              onChange={onHandleChange}
            >
              <option>Selected audiance</option>
              {pretargets.map(({ billingId, configName }, index) => (
                <option value={billingId} key={index}>
                  {configName}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className=" ">
          <div>
            <label className="text-sm">Interests Category</label>
            <select
              className="form-style"
              required
              onChange={(e) => {
                console.log(e.target.value);
                setSelectedCategory(e.target.value);
              }}
            >
              <option>Selected interest</option>
              {iabCategories.map((item, index) => (
                <option value={index} key={index}>
                  {item.category}
                </option>
              ))}
            </select>
          </div>
        </div>
        {selectedCategory && (
          <div className=" ">
            <div>
              <label className="text-sm">Interests*</label>
              <select
                className="form-style"
                name="interest"
                onChange={(e) => {
                  setInterest([
                    ...interest,
                    iabCategories[selectedCategory].categories.filter(
                      (item) => item.value == e.target.value
                    )[0],
                  ]);
                }}
              >
                {iabCategories[selectedCategory].categories
                  .filter((item) => !interest.includes(item))
                  .map(({ value, name }, index) => (
                    <option value={value} key={index}>
                      {name}
                    </option>
                  ))}
              </select>
              <div className="flex flex-wrap">
                {interest.map((item) => {
                  return (
                    <div className="text-sm bg-border py-1 px-3 rounded-full me-2 mb-2 mt-2 flex items-center space-x-1">
                      <div>{item.name}</div>
                      <IoCloseSharp
                        onClick={() => {
                          setInterest([...interest.filter((e) => e != item)]);
                        }}
                        className="size-4 cursor-pointer"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {/* Added Start Date and End Date ----- End */}
      </div>
      <button
        type="submit"
        className="py-3 px-3 bg-primary text-white rounded-md mt-8 font-medium w-40 flex justify-center items-center"
      >
        {isLoading ? <Spinner /> : "Submit"}
      </button>
    </form>
    // </LocalizationProvider>
  );
}

export default NewAudience;
