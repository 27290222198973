import { useContext, useEffect, useState } from "react";
import {
  fetchLinkedZonesByCampaignId,
  linkZoneToCampaign,
  unlinkZoneFromCampaign,
} from "../../../../../../api/ssp-api";
import {
  fetchSspZones,
  linkSspZone,
  unlinkSspZone,
} from "../../../../../../api/api";
import { AuthContext } from "../../../../../../provider/AuthContext";
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import { StepsContext } from "../create-campaign";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../../../../components/loader";

export default function CampaignZones({
  savedCampaign,
  credentials,
  token,
  agencyId,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [loading, setLoading] = useState(true);
  const [zoneLoading, setZoneLoading] = useState(true);
  const [zones, setZones] = useState([]);
  const [linkedZones, setLinkedZones] = useState([]);
  const [filter, setFilter] = useState("");
  const { activeStep, setActiveStep } = useContext(StepsContext);
  const navigate = useNavigate();
  const { uuid } = useParams();
  const isPremium = JSON.parse(localStorage.getItem("isPremium"));

  const campaignId = savedCampaign.campaignId
    ? savedCampaign.campaignId
    : localStorage.getItem("campaignId");

  useEffect(() => {
    setLoading(true);
    fetchLinkedZonesByCampaignId(uuid, credentials)
      .then((res) => {
        console.log("linked zones", res.data);
        setLinkedZones(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });

    fetchSspZones(token, isPremium == true ? "premium" : "mass", agencyId)
      .then((response) => {
        console.log("Ssp Zones", response.data);
        setZones(response.data.sspZones);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setZoneLoading(false);
      });
  }, []); // Removed zones from dependencies

  const handleLinkCampaign = (zoneId) => {
    setZoneLoading(true);

    const { username, password } = JSON.parse(localStorage.getItem("ssp"));

    let removedZone = null;
    let updatedZones = zones.filter((zone) => {
      if (zone.zone_id === zoneId) {
        removedZone = zone;
        return false; // Remove from zones
      }
      return true; // Keep in zones
    });

    if (removedZone) {
      const newLinkedZone = {
        zoneId: removedZone.zone_id,
        zoneName: removedZone.zone_name,
        type: removedZone.type,
        width: removedZone.width,
        height: removedZone.height,
      };

      setLinkedZones([...linkedZones, newLinkedZone]);
    } else {
      console.log(`Zone with ID ${zoneId} not found in zones.`);
    }

    // Update the zones without the removed zone
    setZones(updatedZones);

    linkZoneToCampaign(zoneId, uuid, username, password)
      .then((response) => {
        console.log(response.data);
        const data = {
          zoneId: zoneId,
        };

        linkSspZone(data, token)
          .then((response) => {
            console.log(response.data);
            toast.success("Linked successfully");
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setZoneLoading(false);
      });
  };

  const handleUnlinkCampaign = (zoneId) => {
    setLoading(true);

    const { username, password } = JSON.parse(localStorage.getItem("ssp"));

    let removedLinkedZone = null;
    let updatedLinkedZones = linkedZones.filter((linkedZone) => {
      if (linkedZone.zoneId === zoneId) {
        removedLinkedZone = linkedZone;
        return false; // Remove from linkedZones
      }
      return true; // Keep in linkedZones
    });

    if (removedLinkedZone) {
      const newZone = {
        zone_id: removedLinkedZone.zoneId,
        zone_name: removedLinkedZone.zoneName,
        type: removedLinkedZone.type,
        width: removedLinkedZone.width,
        height: removedLinkedZone.height,
      };

      // Add the transformed zone back to zones
      setZones([...zones, newZone]);
    } else {
      console.log(`Linked zone with ID ${zoneId} not found in linkedZones.`);
    }

    setLinkedZones(updatedLinkedZones);

    unlinkZoneFromCampaign(zoneId, uuid, username, password)
      .then((response) => {
        console.log(response.data);
        const data = {
          zoneId: zoneId,
        };

        unlinkSspZone(data, token)
          .then((response) => {
            toast.success("Unlinked successfully");
            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className=" w-full grid grid-cols-1 gap-5 items-stretch">
      <div item xs={12}>
        {loading ? (
          <div item>
            <Loader />
          </div>
        ) : (
          <div item xs={12}>
            <p className="text-lg">Linked zones</p>
            <div className="bg-white px-5 py-5 w-full rounded-md mt-3 border border-border">
              <div className="flex justify-end space-x-2">
                <input
                  label="Filter by name or campaign name"
                  placeholder="Search here"
                  className="py-2 rounded-md text-sm border-border focus:border-primary focus:ring-primary"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                />
              </div>
              <table className="text-sm table w-full">
                <thead className="text-muted">
                  <tr className="">
                    <th className="py-3 text-sm text-start">Zone ID</th>
                    <th className="text-sm text-start py-3">Zone Name</th>
                    <th className="text-sm text-start py-3">Actions</th>
                  </tr>
                </thead>
                <tbody className="text-start">
                  {linkedZones
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item) => (
                      <tr className="text-start even:bg-background text-textColor even:border-y-[1px]  hover:text-textColor w-full  cursor-pointer">
                        <td className="text-start py-3 px-0 ">{item.zoneId}</td>
                        <td className="text-start py-3 px-0 ">
                          {item.zoneName}
                        </td>
                        <td className="text-start py-3 px-0 ">
                          <button
                            className="py-1 px-3 rounded-md bg-red-500 text-white text-sm"
                            onClick={() => handleUnlinkCampaign(item.zoneId)}
                          >
                            Unlink
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <div className="flex space-x-1 items-center text-sm mt-4 justify-end text-gray-500 me-3">
                <h1 className=" translate-x-5">Rows per page</h1>
                <select
                  value={rowsPerPage}
                  onChange={(e) => {
                    setRowsPerPage(e.target.value);
                  }}
                  className="px-6 py-2 h-12 border-0 focus:border-none focus:ring-0 text-sm"
                >
                  <option className="" value={5}>
                    5
                  </option>
                  <option className="" value={8}>
                    8
                  </option>
                </select>
                <div
                  onClick={() => {
                    if (page > 0) {
                      setPage(page - 1);
                    }
                  }}
                  className="size-5 bg-primary rounded-md flex items-center text-white justify-center cursor-pointer"
                >
                  <MdOutlineChevronLeft />
                </div>
                <h1>
                  {page + 1} out of{" "}
                  {Math.ceil(linkedZones.length / rowsPerPage)}
                </h1>
                <div
                  onClick={() => {
                    if (page < Math.round(linkedZones.length / rowsPerPage)) {
                      setPage(page + 1);
                    }
                  }}
                  className="size-5 bg-primary rounded-md flex items-center cursor-pointer text-white justify-center"
                >
                  <MdOutlineChevronRight />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {zoneLoading ? (
        <div item xs={12}>
          <Loader />
        </div>
      ) : (
        <div item xs={12}>
          <p className="text-lg">Available zones</p>
          <div className="bg-white px-5 py-5 w-full rounded-md mt-3 border border-border">
            <div className="flex justify-end space-x-2">
              <input
                label="Filter by name or campaign name"
                placeholder="Search here"
                className="py-2 rounded-md text-sm border-border focus:border-primary focus:ring-primary"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />
            </div>
            <table className="text-sm table w-full">
              <thead className="text-muted">
                <tr className="">
                  <th className="py-3 text-sm text-start">Zone ID</th>
                  <th className="text-sm text-start py-3">Zone Name</th>
                  <th className="text-sm text-start py-3">Actions</th>
                </tr>
              </thead>
              <tbody className="text-start">
                {zones
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item) => (
                    <tr className="text-start even:bg-background text-textColor even:border-y-[1px]  hover:text-textColor w-full  cursor-pointer">
                      <td className="text-start py-3 px-0 ">{item.zone_id}</td>
                      <td className="text-start py-3 px-0 ">
                        {item.zone_name}
                      </td>
                      <td className="text-start py-3 px-0 ">
                        <button
                          className="py-1 px-3 rounded-md bg-primary text-white text-sm"
                          onClick={() => handleLinkCampaign(item.zone_id)}
                        >
                          Link
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="flex space-x-1 items-center text-sm mt-4 justify-end text-gray-500 me-3">
              <h1 className=" translate-x-5">Rows per page</h1>
              <select
                value={rowsPerPage}
                onChange={(e) => {
                  setRowsPerPage(e.target.value);
                }}
                className="px-6 py-2 h-12 border-0 focus:border-none focus:ring-0 text-sm"
              >
                <option className="" value={5}>
                  5
                </option>
                <option className="" value={8}>
                  8
                </option>
              </select>
              <div
                onClick={() => {
                  if (page > 0) {
                    setPage(page - 1);
                  }
                }}
                className="size-5 bg-primary rounded-md flex items-center text-white justify-center cursor-pointer"
              >
                <MdOutlineChevronLeft />
              </div>
              <h1>
                {page + 1} out of {Math.ceil(linkedZones.length / rowsPerPage)}
              </h1>
              <div
                onClick={() => {
                  if (page < Math.round(linkedZones.length / rowsPerPage)) {
                    setPage(page + 1);
                  }
                }}
                className="size-5 bg-primary rounded-md flex items-center cursor-pointer text-white justify-center"
              >
                <MdOutlineChevronRight />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex space-x-2 mt-6">
        <button
          className="py-2  rounded-md  text-textColor  justify-center flex hover:text-primary transition-all duration-200"
          onClick={() => {
            setActiveStep(activeStep - 1);
          }}
          type="submit"
        >
          Back
        </button>
        <button
          className="py-2 px-3 rounded-md    w-24 justify-center flex bg-primary text-white  cursor-pointer scale-105 transition-all duration-200"
          onClick={() => {
            // navigate("/ssp");
          }}
        >
          Finish
        </button>
      </div>
    </div>
  );
}
