import axios from "axios";

export let sspURL = process.env.REACT_APP_SSP_URL;
const user = localStorage.getItem("ssp");
let username, password, base64Credentials, role;

if (user) {
  try {
    const userObj = JSON.parse(user);
    username = userObj.username;
    password = userObj.password;
    const credentials = `${username}:${password}`;
    base64Credentials = btoa(credentials);
  } catch (error) {
    // Handle the error if the data is not in JSON format
    console.error("Error parsing user data:", error);
  }
}

export const getUserName = () => {
  return username;
};

let config = {
  // baseURL: sspURL,
  headers: {
    Authorization: `Basic ${base64Credentials}`,
    // Cookie: "sessionID=6871509279d1656b02288a5c51c91e02",
  },
};

export const getRoles = () => {
  const dspUser = localStorage.getItem("kwanza");
  if (dspUser) {
    try {
      const dspObj = JSON.parse(dspUser);
      role = dspObj.role;
    } catch (error) {
      // Handle the error if the data is not in JSON format
      console.error("Error parsing user data:", error);
    }
  }
  return role;
};

export const fetchAdvertiserByAgencyId = (agencyId, credentials) => {
  return axios.get(`${sspURL}/adv/agc/${agencyId}`, {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  });
};

export const fetchAdvertisersByAgencyId = (agencyId, username, password) => {
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  return axios.get(`${sspURL}/adv/agc/2`, {
    headers: {
      Authorization: `Basic ${base64Credentials}`,
    },
  });
};
export const fetchCampaigns = (advertiserId) => {
  return axios.get(`${sspURL}/cam/adv/${advertiserId}`, config);
};
export const fetchCampaignsByAdvertiser = (advertiserId, credentials) => {
  return axios.get(`${sspURL}/cam/adv/${advertiserId}`, {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  });
};

export const deleteSspCampaignApi = (campaignId) => {
  return axios.delete(`${sspURL}/cam/${campaignId}`, config);
};

export const addAdvertiserApi = (advertiser, credentials) => {
  return axios.post(`${sspURL}/adv/new`, advertiser, {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  });
};
export const addAdvertiserUserApi = (advertiserUser) => {
  return axios.post(`${sspURL}/usr/new`, advertiserUser, config);
};

export const deleteSspAdvertiserApi = (advertiserId) => {
  return axios.delete(`${sspURL}/adv/${advertiserId}`, config);
};

export const fetchCampaignListByAdvertiserId = (advertiserId, credentials) => {
  return axios.get(`${sspURL}/cam/adv/${advertiserId}`, {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  });
};

export const fetchAdvertiserCampaignStatsApi = (
  advertiserId,
  credentials,
  startDate,
  endDate
) => {
  const endpoint = `${sspURL}/adv/${advertiserId}/statistics/campaign/${startDate}/${endDate}`;
  console.log(endpoint);
  return axios.get(endpoint, {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  });
};

export const fetchAgencyCampaignStatsApi = (
  agencyId,
  credentials,
  startDate,
  endDate
) => {
  const endpoint = `${sspURL}/agc/${agencyId}/statistics/campaign/${startDate}/${endDate}`;
  console.log(endpoint);
  return axios.get(endpoint, {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  });
};

export const fetchCampaignDailyStatsApi = (
  campaignId,
  startDate,
  credentials
) => {
  let endDate = getFormattedDate();
  return axios.get(
    `${sspURL}/cam/${campaignId}/statistics/publisher/${startDate}/${endDate}`,
    {
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    }
  );
};

export const fetchAdvertiserPublisherStatsApi = (
  advertiserId,
  startDate,
  credentials
) => {
  let endDate = getFormattedDate();
  return axios.get(
    `${sspURL}/adv/${advertiserId}/statistics/publisher/${startDate}/${endDate}`,
    {
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    }
  );
};

export const fetchPublisherDailyStatsApi = (
  publisherId,
  startDate,
  credentials
) => {
  let endDate = getFormattedDate();
  return axios.get(
    `${sspURL}/pub/${publisherId}/statistics/daily/${startDate}/${endDate}`,
    {
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    }
  );
};

export const fetchCampaignPublisherStatsApi = (
  campaignId,
  startDate,
  credentials
) => {
  let endDate = getFormattedDate();
  return axios.get(
    `${sspURL}/cam/${campaignId}/statistics/publisher/${startDate}/${endDate}`,
    {
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    }
  );
};
export const fetchZoneStatsApi = (campaignId, startDate, credentials) => {
  let endDate = getFormattedDate();
  return axios.get(
    `${sspURL}/cam/${campaignId}/statistics/zone/${startDate}/${endDate}`,
    {
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    }
  );
};

export const fetchBannerByCampaignId = (campaignId, credentials) => {
  return axios.get(`${sspURL}/bnn/cam/${campaignId}`, {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  });
};

export const fetchCampaignZoneStatsApi = (
  campaignId,
  startDate,
  credentials
) => {
  let endDate = getFormattedDate();
  return axios.get(
    `${sspURL}/cam/${campaignId}/statistics/zone/${startDate}/${endDate}`,
    {
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    }
  );
};

export const fetchPublisherBannerStatsApi = (
  publisherId,
  startDate,
  credentials
) => {
  let endDate = getFormattedDate();
  return axios.get(
    `${sspURL}/pub/${publisherId}/statistics/banner/${startDate}/${endDate}`,
    {
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    }
  );
};
export const fetchCampaignBannerStatsApi = (
  campaignId,
  startDate,
  credentials
) => {
  let endDate = getFormattedDate();
  return axios.get(
    `${sspURL}/cam/${campaignId}/statistics/banner/${startDate}/${endDate}`,
    {
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    }
  );
};

export const fetchDailyCampaignsStatisticsApi = () => {
  return axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log(error);
    });
};

export const fetchSspUsers = (accountId) => {
  return axios.get(`${sspURL}/usr/acc/${accountId}`, config);
};

export const deleteSspUser = (userId) => {
  return axios.delete(`${sspURL}/usr/${userId}`, config);
};

export const fetchPublisherWebsites = (credentials, agencyId) => {
  return axios.get(`${sspURL}/pub/agc/${agencyId}`, {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  });
};

export const addPublisherWebsite = (website, credentials) => {
  return axios.post(`${sspURL}/pub/new`, website, {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  });
};

export const fetchPublisherCampaignStats = (publisherId, credentials) => {
  let startDate = "2023-01-01";
  let endDate = getFormattedDate();
  return axios.get(
    `${sspURL}/pub/${publisherId}/statistics/campaign/${startDate}/${endDate}`,
    {
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    }
  );
};

export const fetchZonesByPublisherId = (id, credentials) => {
  return axios.get(`${sspURL}/zon/pub/${id}`, {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  });
};

export const addZone = (zone) => {
  return axios.post(`${sspURL}/zon/new`, zone, config);
};

export const fetchZoneById = (id, credentials) => {
  return axios.get(`${sspURL}/zon/${id}`, {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  });
};

export const editZone = (zone, credentials) => {
  return axios.put(`${sspURL}/zon/${zone.zoneId}`, zone, {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  });
};

export const fetchZoneIc = (zoneId, zone) => {
  return axios.post(`${sspURL}/zon/${zoneId}/ic`, zone, config);
};
export const fetchZoneListByCampaignId = (campaignId) => {
  return axios.get(`${sspURL}/zon/cam/${campaignId}`, config);
};

export const linkZoneToBanner = (zoneId, bannerId, credentials) => {
  return axios.post(`${sspURL}/zon/${zoneId}/ban/${bannerId}`, {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  });
};
export const linkZoneToCampaign = (zoneId, campaignId, user, password) => {
  const credentials = `${user}:${password}`;
  // Convert username:password to its base64 representation
  const token = btoa(credentials);
  console.log(zoneId);
  console.log(campaignId);
  return axios.post(
    `${sspURL}/zon/${zoneId}/cam/${campaignId}`,
    {},
    {
      headers: {
        Authorization: `Basic ${token}`,
      },
    }
  );
};

export const fetchLinkedZonesByCampaignId = (campaignId, credentials) => {
  return axios.get(`${sspURL}/zon/cam/${campaignId}`, {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  });
};

export const unlinkZoneFromCampaign = (zoneId, campaignId, user, password) => {
  const credentials = `${user}:${password}`;
  // Convert username:password to its base64 representation
  const token = btoa(credentials);
  return axios.delete(`${sspURL}/zon/${zoneId}/cam/${campaignId}`, {
    headers: {
      Authorization: `Basic ${token}`,
    },
  });
};
export const addCampaign = (campaign, credentials) => {
  return axios.post(`${sspURL}/cam/new`, campaign, {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  });
};

export const fetchCampaignById = (id, credentials) => {
  console.log(id);
  return axios.get(`${sspURL}/cam/${id}`, {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  });
};

export const editCampaign = (id, campaign, credentials) => {
  return axios.post(`${sspURL}/cam/${id}`, campaign, {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  });
};

export const uploadImageCreative = (creative) => {
  return axios.post(`${sspURL}/bnn/upload`, creative, config);
};

export const addCreative = (banner, credentials) => {
  return axios.post(`${sspURL}/bnn/new`, banner, {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  });
};

export const fetchCreativesByCampaignId = (campaignId, credentials) => {
  return axios.get(`${sspURL}/bnn/cam/${campaignId}`, {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  });
};

export const fetchCreativeById = (id, credentials) => {
  return axios.get(`${sspURL}/bnn/${id}`, {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  });
};

export const addSspUser = (user, base64Credentials) => {
  return axios.post(`${sspURL}/usr/new`, user, {
    headers: {
      Authorization: `Basic ${base64Credentials}`,
    },
  });
};

export const fetchSspBanners = (credentials, campaignId) => {
  return axios.get(`${sspURL}/bnn/cam/${campaignId}`, {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  });
};

export const fetchBannersByZoneId = (credentials, zoneId) => {
  return axios.get(`${sspURL}/bnn/zon/${zoneId}`, {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  });
};

export const fetchBannerById = (bannerId, credentials) => {
  return axios.get(`${sspURL}/bnn/${bannerId}`, {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  });
};

function getFormattedDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}
