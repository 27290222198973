// PublisherTable.js
import React, { useContext, useState } from "react";

import { MdOutlineChevronRight, MdOutlineChevronLeft } from "react-icons/md";
import { deletePublisherWebsite } from "../../api/api";
import { AuthContext } from "../../provider/AuthContext";
import Spinner from "../../components/spinner";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DeleteButton from "../../components/deleteButton";
import ModifyButton from "../../components/modify-button";

const PublisherTable = ({ data, refresh, setRefresh }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [filter, setFilter] = useState("");
  const { userDetails } = useContext(AuthContext);
  const { token } = JSON.parse(userDetails);
  const navigate = useNavigate();
  const { website, setWebsite } = useState(null);
  const filteredData = data.filter(
    (item) => item.website.toLowerCase().includes(filter.toLowerCase()) // Filter by campaign name
  );
  const [selected, setSelected] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const handleDelete = (id) => {
    try {
      setSelected(id);
      setIsDeleting(true);
      console.log(id);
      deletePublisherWebsite(id, token).then((response) => {
        toast.success("Deleted successfully");
        console.log(response);
        setIsDeleting(false);
        setRefresh(refresh + 1);
      });
    } catch (error) {
      console.log(error.data.response);
      toast.error(error.data.response.data.message);
    }
  };

  return (
    <div className="bg-white px-5 py-5 w-full rounded-md border border-border shadow-2xl">
      <div className="flex justify-end space-x-2">
        <input
          label="Filter by name or campaign name"
          placeholder="Search here"
          className="py-2 rounded-md text-sm border-border focus:border-primary focus:ring-primary"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>
      <table className="text-sm table w-full mt-4">
        <thead className="text-muted">
          <tr className="">
            <th className="text-sm text-start py-3">Website</th>
            <th className="text-sm text-start py-3">Publisher Name</th>
            <th className="text-sm text-start py-3">Contact Name</th>
            <th className="text-sm text-start py-3">Email Address</th>
            <th className="text-sm text-start py-3">Type</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="text-start">
          {filteredData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item) => (
              <tr className="text-start even:bg-background text-textColor even:border-y-[1px]  hover:text-textColor w-full  cursor-pointer">
                <td className="text-start py-3 px-0 ">{item.website}</td>
                <td className="text-start py-3 px-0 ">{item.publisher_name}</td>
                <td className="text-start py-3 px-0 ">{item.contact_name}</td>
                <td className="text-start py-3 px-0 ">{item.email}</td>
                <td className="text-start py-3 px-0 ">
                  {(item.category && item.category.name) || "Others"}
                </td>

                <td className="text-start px-0 py-3">
                  <ModifyButton
                    id={item.publisher_id}
                    onDelete={() => {
                      handleDelete(item.publisher_id);
                    }}
                    onEdit={() => {
                      navigate(`/websites/${item.publisher_id}`);
                    }}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="flex space-x-1 items-center text-sm mt-4 justify-end text-gray-500 me-3">
        <h1 className=" translate-x-5">Rows per page</h1>
        <select
          value={rowsPerPage}
          onChange={(e) => {
            setRowsPerPage(e.target.value);
          }}
          className="px-6 py-2 h-12 border-0 focus:border-none focus:ring-0 text-sm"
        >
          <option className="" value={5}>
            5
          </option>
          <option className="" value={8}>
            8
          </option>
        </select>
        <div
          onClick={() => {
            if (page > 0) {
              setPage(page - 1);
            }
          }}
          className="size-5 bg-primary rounded-md flex items-center text-white justify-center cursor-pointer"
        >
          <MdOutlineChevronLeft />
        </div>
        <h1>
          {page + 1} out of {Math.ceil(data.length / rowsPerPage)}
        </h1>
        <div
          onClick={() => {
            if (page < Math.round(data.length / rowsPerPage)) {
              setPage(page + 1);
            }
          }}
          className="size-5 bg-primary rounded-md flex items-center cursor-pointer text-white justify-center"
        >
          <MdOutlineChevronRight />
        </div>
      </div>
    </div>
  );
};

export default PublisherTable;
