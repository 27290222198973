import { useEffect, useState } from "react";
import { IoLogOut } from "react-icons/io5";
import { logoutAPI } from "../api/api";
import { useNavigate } from "react-router-dom";
import { AiFillBulb, AiOutlineBulb } from "react-icons/ai";
import { MdLightMode, MdOutlineLightMode } from "react-icons/md";

const Navbar = ({ isDark, setIsDark }) => {
  const user = localStorage.getItem("kwanza");
  const userObj = JSON.parse(user);
  const [showLogout, setShowLogout] = useState(false);
  // const role = userObj.role;
  const navigate = useNavigate();
  const onSignout = () => {
    const { token } = user ? JSON.parse(user) : {};
    logoutAPI(token)
      .then((_) => {
        navigate("/login");
        localStorage.removeItem("kwanza");
      })
      .catch(() => {
        navigate("/login");
        localStorage.removeItem("kwanza");
      });
  };

  //add event listener to close the logout dropdown when clicked outside
  useEffect(() => {
    if (showLogout) {
      window.onclick = (e) => {
        setShowLogout(false);
      };
    }
  }, [showLogout]);

  return (
    <div className="w-[82%] 2xl:w-[87%] bg-white  fixed border-b-2 dark:text-white border-border z-30">
      <div>
        <div className="flex justify-between h-20 px-6 items-center">
          <div
            onClick={() => {
              navigate("/");
            }}
            className="object-cover"
          >
            {/* <img className="h-10" src="/Kwanza 1.svg" /> */}
          </div>
          <div className="flex space-x-6 items-center">
            <div className="flex items-center  space-x-2 relative">
              <div
                onClick={() => {
                  setIsDark(!isDark);
                }}
                className=" cursor-pointer text-dark text-xl"
              >
                {isDark ? <MdOutlineLightMode /> : <MdLightMode />}
              </div>
              <p className="text-sm font-medium text-textColor">
                {userObj.name}
              </p>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setShowLogout(!showLogout);
                }}
                className="h-12 w-12 cursor-pointer rounded-full bg-primary flex justify-center items-center text-white "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="size-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              {showLogout && (
                <div
                  onClick={onSignout}
                  className="absolute transition-all duration-300 right-0 mt-28 rounded-md cursor-pointer flex items-center  space-x-1 hover:text-red-500 group py-2 w-28 px-3 bg-white border-2 border-border shadow-lg"
                >
                  <IoLogOut className="text-gray-400 size-5 transition-all duration-300 group-hover:text-red-500 " />
                  <div>
                    <h1 className="text-sm">Logout</h1>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
