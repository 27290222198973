import React, { useState } from "react";

import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import moment from "moment";

const DspUserTable = ({ data, handleDelete }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [filter, setFilter] = useState("");
  const filteredRows = data.filter((item) =>
    item.name.toLowerCase().includes(filter.toLowerCase())
  );
  return (
    <div>
      <div className="bg-white px-5 py-5 w-full rounded-md border border-border">
        <div className="flex justify-end">
          <input
            className="py-2 rounded-md border-border text-sm  focus:border-primary focus:ring-primary"
            placeholder="Search here"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
        <table className="text-sm table w-full mt-4">
          <thead className="text-muted">
            <tr className="">
              <th className="py-3 text-sm text-start">Id</th>
              <th className="text-sm text-start py-3">Name</th>
              <th className="text-sm text-start py-3">Email</th>
              <th className="text-sm text-start py-3">Admin</th>
              <th className="text-sm text-start py-3">Created At</th>
            </tr>
          </thead>
          <tbody className="text-start">
            {filteredRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item) => (
                <tr className="text-start even:bg-background text-textColor even:border-y-[1px]  hover:text-textColor w-full  cursor-pointer">
                  <td className="text-start py-3 px-0 ">{item.id}</td>
                  <td className="text-start py-3 px-0 ">{item.name}</td>
                  <td className="text-start py-3 px-0 ">{item.email}</td>
                  <td className="text-start py-3 px-0 ">
                    {parseInt(item.admin) == 0 ? "Yes" : "No"}
                  </td>
                  <td className="text-start py-3 px-0 ">
                    {moment(item.created_at).format("MMM DD, YYYY")}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="flex space-x-1 items-center text-sm mt-4 justify-end text-gray-500 me-3">
          <h1 className=" translate-x-5">Rows per page</h1>
          <select
            value={rowsPerPage}
            onChange={(e) => {
              setRowsPerPage(e.target.value);
            }}
            className="px-6 py-2 h-12 border-0 focus:border-none focus:ring-0 text-sm"
          >
            <option className="" value={5}>
              5
            </option>
            <option className="" value={8}>
              8
            </option>
          </select>
          <div
            onClick={() => {
              if (page > 0) {
                setPage(page - 1);
              }
            }}
            className="size-5 bg-primary rounded-md flex items-center text-white justify-center cursor-pointer"
          >
            <MdOutlineChevronLeft />
          </div>
          <h1>
            {page + 1} out of {Math.ceil(data.length / rowsPerPage)}
          </h1>
          <div
            onClick={() => {
              if (page < Math.round(data.length / rowsPerPage)) {
                setPage(page + 1);
              }
            }}
            className="size-5 bg-primary rounded-md flex items-center cursor-pointer text-white justify-center"
          >
            <MdOutlineChevronRight />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DspUserTable;
